.landing_div {
  padding-bottom: 5rem;
  background-color: #ffffff;
  padding-top: 0.2rem;
  overflow: hidden;
}

.firstForDesktop {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  padding: 0rem 3rem;
}

.first_section {
  background: rgb(54, 131, 255);
  background: linear-gradient(
    300deg,
    rgba(54, 131, 255, 1) 45%,
    rgba(41, 123, 255, 1) 45%
  );
  /* background-color: #0eb6ff; */
  padding: 2rem 2.5rem;
  margin: 0.5rem 1rem;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.first_section_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headingfirst {
  margin: 0;
  font-size: 2rem;
}

.Section_Icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1.5rem;
}

.icon-label > img {
  height: 6rem;
  object-fit: contain;
}

.icon-label {
  font-size: 1.2rem;
  font-weight: 700;
  color: white;
  display: flex;
  align-items: flex-end;
  max-width: 13rem;
}

.buttonStyle1 {
  font-size: 1.2rem;
  border: none;
  border-radius: 0.2rem;
  padding: 0.8rem 0.6rem;
  background-color: #00008b;
  color: white;
  font-weight: 600;
  cursor: pointer;
}

.second_section {
  display: grid;
  grid-template-rows: auto auto;
  align-items: center;
}

.secondsection_first,
.secondsection_Second {
  flex: 1;
  padding: 0.25rem;
  margin: 0.5rem 1rem;
  text-align: left;
  height: 8.2rem;
  border-radius: 0.1rem;
  cursor: pointer;
  position: relative;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.text_blue.desktopElement {
  position: absolute;
  top: 0;
  right: 0;
  border-top-right-radius: 0.4rem;
}

.subflex_text > h2 {
  font-size: 1.2rem;
  margin: 0;
  margin: 0.5rem 0;
}

.secondsection_first > h2,
.secondsection_Second > h2 {
  margin: 0;
  margin-bottom: 1rem;
}

.secondsection_first {
  background: rgb(217, 242, 217);
  background: linear-gradient(
    185deg,
    rgba(217, 242, 217, 1) 40%,
    rgba(225, 244, 225, 1) 40%
  );
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.subflex_icon > img {
  position: absolute;
  left: 4px;
  bottom: 0px;
  height: 7.5rem;
  object-fit: contain;
}

.secondsection_Second {
  background: rgb(255, 230, 238);
  background: linear-gradient(
    175deg,
    rgba(255, 230, 238, 1) 40%,
    rgba(255, 237, 243, 1) 40%
  );
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.text_blue {
  padding: 0.4rem;
  font-size: 0.8rem;
  background-color: #000c83;
  color: white;
  width: fit-content;
}

.subflex_text > h4 {
  position: relative;
  top: 2px;
  margin: 0px;
  right: -5px;
  text-align: left;
  font-weight: 500;
}

.secondsection_subflex {
  display: flex;
  align-items: center;
}

.subflex_icon {
  flex: 1;
}

.subflex_text {
  flex: 3;
}

.fourth_section {
  margin-top: 1rem;
  /* margin: 1rem 2rem; */
  padding: 2rem;
  /* overflow: hidden; */
}

.fourth_section_heading,
.fifth_section_heading {
  margin: 1rem 2rem;
  font-size: 1.5rem;
  font-weight: bold;
  /* margin: 0.8rem 0; */
  color: #000c83;
}

.SelfCheck_slider {
  display: flex;
  overflow-x: scroll;
  box-sizing: content-box;
}

.SelfCheck_slider::-webkit-scrollbar {
  display: none;
}

.fifth_section {
  /* margin: 1rem 2rem; */
  padding: 2rem;
}

.fifth_section_heading > button {
  border-radius: 0.3rem;
  border: none;
  color: white;
  padding: 0.3rem;
  background-color: #1565c0;
}

.labtest_cards_list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.bottomNavigation {
  display: none;
}

.mobileElement {
  display: none;
}

.desktopElement {
  display: block;
}

@media (max-width: 1500px) {
  .scantext {
    max-width: 13rem;
  }
  .discounttext {
    top: 2px;
  }
  .name {
    top: 4px;
  }
}

@media (max-width: 1300px) {
  .firstForDesktop {
    padding-left: 0rem;
    padding-right: 0rem;
  }
}

@media (max-width: 900px) {
  .firstForDesktop {
    display: block;
    padding: 0;
  }

  .subflex_text {
    margin-left: 5rem;
  }

  .subflex_icon > img {
    margin-left: 2rem;
  }

  .desktopElement {
    display: none;
  }

  .icon-label {
    font-size: 1rem;
    max-width: 12rem;
  }

  .icon-label > img {
    height: 5rem;
  }

  .desktopElement {
    display: none;
  }

  .mobileElement {
    display: block;
  }

  .second_section {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .secondsection_first,
  .first_section,
  .secondsection_Second {
    padding: 1rem;
    margin: 1rem;
  }

  .first_section {
    padding: 2rem 3rem;
  }

  .fourth_section_heading,
  .fifth_section_heading {
    margin: 1rem;
  }

  .fourth_section,
  .fifth_section {
    padding: 1rem;
  }

  .labtest_cards {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .secondsection_first,
  .secondsection_Second {
    height: 10.5rem;
  }
}

@media (max-width: 800px) {
  .secondsection_first,
  .first_section,
  .secondsection_Second {
    padding: 0.5rem;
    margin: 0.5rem;
  }

  .first_section {
    padding: 2rem 1.5rem;
  }

  .fourth_section_heading,
  .fifth_section_heading {
    margin: 1rem 0.5rem;
  }

  .fourth_section,
  .fifth_section {
    padding: 0.5rem;
  }

  .Section_Icon > img {
    width: 10rem;
    object-fit: contain;
  }
  .subflex_icon > img {
    margin-left: 23px;
  }
}

@media (max-width: 700px) {
  .secondsection_first,
  .secondsection_Second {
    font-size: 80%;
  }

  .text_blue {
    padding: 0.4rem;
  }

  .fourth_section {
    padding-right: 0;
  }
  .subflex_text {
    margin-left: 3rem;
  }

  .subflex_icon > img {
    margin-left: 1rem;
  }
}

@media (max-width: 550px) {
  .bottomNavigation {
    display: block;
  }

  .Section_Icon > img {
    width: 8rem;
    object-fit: contain;
  }

  .subflex_icon > img {
    height: 5rem;
    object-fit: contain;
    margin-left: 0rem;
  }

  .text_blue {
    position: absolute;
    top: 30px;
    right: 10px;
    padding: 0.2rem;
    font-size: 10px;
  }

  .secondsection_first > h2,
  .secondsection_Second > h2 {
    position: absolute;
    margin-bottom: 0.2rem;
    font-size: 0.8rem;
    top: 5px;
    left: 10px;
    /* right: 16px; */
  }

  .subflex_text {
    font-size: 0.6rem;
    font-weight: 400;
  }

  .labtest_cards_list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .subflex_text {
    margin-left: 1rem;
  }
}

@media (max-width: 500px) {
  .secondsection_first,
  .secondsection_Second {
    font-size: 65%;
    height: 6.5rem;
  }
  .secondsection_first {
    margin-right: 3px;
  }
  .secondsection_Second {
    margin-left: 3px;
  }

  .icon-label {
    font-size: 0.6rem;
    font-weight: 700;
    margin-right: 10px;
    max-width: 8rem;
  }

  .icon-label > img {
    height: 3rem;
    object-fit: contain;
  }

  .subflex_text > h4 {
    position: relative;
    top: 52px;
    left: 3px;
    margin: 0px;
    text-align: center;
    font-weight: 500;
    margin: 0;
  }

  .buttonStyle1 {
    padding: 0.6rem 0.5rem;
    font-size: 0.9rem;
  }

  .first_section {
    padding: 1rem;
  }

  .bottomDrawer {
    margin-top: 0.5rem;
  }

  .Section_Icon > img {
    width: 7.5rem;
    object-fit: contain;
  }

  .headingfirst {
    font-size: 1rem;
    font-weight: bolder;
  }

  .fifth_section_heading,
  .fourth_section_heading {
    font-size: 1rem;
  }
}

@media (max-width: 400px) {
  .Section_Icon > img {
    width: 6.5rem;
    object-fit: contain;
  }
}
